<template>
  <div
    style="
      background-color: #171726;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      padding-bottom: 60px;"
  >
    <div
      class="top_bg"
      style="background-image: url('img/myIncome/re_top_bg.png')"
    >
      <nav-bar
        class="title_top"
        :icon="'img/backw.png'"
        :textColor="'#ffffff'"
        :backColor="'none'"
      />
      <div class="info_box">
        <div
          class="gold_info_box"
          style="background-image: url('img/myIncome/left_bg.png')"
        >
          <div class="gold_info">
            <span>{{ userInfo.diamonds }}</span>
            <img class="coin" src="img/myIncome/gold.png" alt="" />
          </div>
          <div class="gold_title">金币余额</div>
        </div>
        <div
          class="silver_info_box"
          style="background-image: url('img/myIncome/right_bg.png')"
        >
          <div class="gold_info">
            <span>{{ userInfo.gold }}</span>
            <img class="coin" src="img/myIncome/silver.png" alt="" />
          </div>
          <div class="gold_title">银币余额</div>
        </div>
      </div>
      <div
        class="tip_box"
        style="background-image: url('img/myIncome/tip.png')"
      ></div>
    </div>
    <div>
      <div
        class="re_title"
        style="background-image: url('img/myIncome/title.png')"
      ></div>
      <div class="re_box">
        <div class="re_item" v-for="(item, index) in list" :key="index" @click="payAction(item)">
          <div class="re_item_top">
            <span class="g_color">{{ item.diamonds }}</span>
            <img class="coin_sm" src="img/myIncome/gold.png" alt="" />
          </div>
          <div class="re_item_top">
            <span class="s_color">{{ item.gold }}</span>
            <img class="coin_sm" src="img/myIncome/silver.png" alt="" />
          </div>
          <div class="money">{{item.money+'元'}}</div>
        </div>
      </div>
    </div>
    <div class="mask" v-if="showMask">
            <div class="modal_content">
                <div class="modal_title">支付提示</div>
                <div class="tip_content">本次支付将优惠{{deduct_money}}元</div>
                <div class="btns_box">
                    <div class="btn_item_cancel" @click="showMask = false">取消</div>
                    <div class="btn_item_enter" @click="confirm">去支付</div>
                </div>
            </div>
    </div>
    <div class="agreement" @click="toAgreement">《充值服务协议》</div>
  </div>
</template>
<script src="../../utils/jweixin.js"></script>
<script>
import NavBar from "@/components/navBar.vue";
import { sdPay, rechargeList, userWallet } from "@/api/wallet";
export default {
  name: "setLevelPassword",
  components: {
    NavBar,
  },
  data() {
    return {
      userInfo: {},
      list: [],
      payData:{},
      showMask: false,
      open_id:'',
      deduct_money:0
    };
  },
  created() {
    this.open_id = localStorage.getItem("openid");
    this.getUserWallet();
    this.getReList();
  },
  methods: {
    getReList() {
      let params = {};
      rechargeList(params).then(
        (res) => {
          if (res.code == 10000) {
            this.list = res.data;
          }
        },
        (error) => {
          console.log(error);
          this.$toast.fail(error);
        }
      );
    },
    getUserWallet() {
      userWallet({})
        .then(
          (response) => {
            this.userInfo = response.data;
            console.log(this.userInfo);
          },
          (error) => {
            console.log(error);
            this.$toast.fail(error);
          }
        )
        .catch((error) => {
          this.$toast.fail(error);
        });
    },
    payAction(item){
      let params = {
        open_id:this.open_id,
        recharge_id:item.id
      };
      sdPay(params)
        .then(
          (response) => {
            console.log(response)
            if(response.code==10000){
              this.payData = response.data.payData.credential
              this.deduct_money = response.data.deduct_money
              this.showMask = true
            }
          },
          (error) => {
            console.log(error);
            this.$toast.fail(error);
          }
        )
        .catch((error) => {
          this.$toast.fail(error);
        });
    },
    confirm(){
      this.showMask = false
      this.wxReadyToPay()
    },
    onBridgeReady() {
    let that = this
    WeixinJSBridge.invoke('getBrandWCPayRequest', {
      "appId": that.payData.appId,     //公众号ID，由商户传入     
      "timeStamp": that.payData.timeStamp,     //时间戳，自1970年以来的秒数     
      "nonceStr": that.payData.nonceStr,      //随机串     
      "package": that.payData.package,
      "signType": that.payData.signType,     //微信签名方式：     
      "paySign": that.payData.paySign //微信签名 
    },
      function (res) {
        this.getUserWallet();
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        }
      });
  },
  wxReadyToPay() {
    let that = this
    if (typeof WeixinJSBridge == "undefined") {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(), false);
      } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady());
        document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady());
      }
    } else {
      that.onBridgeReady();
    }
  },
  toAgreement(){
    this.$router.push({ path: "/recharge", query: {} });
  }
  }
};
</script>
<style scoped>
.top_bg {
  width: 100vw;
  height: 564px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.info_box {
  width: 700px;
  height: 221px;
  margin: 20px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gold_info_box {
  width: 344px;
  height: 221px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.silver_info_box {
  width: 344px;
  height: 221px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.tip_box {
  width: 700px;
  height: 117px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 40px auto 0;
}
.gold_info {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: #ffdba1;
  margin-top: 52px;
}
.coin {
  width: 40px;
  height: 40px;
}
.gold_title {
  font-size: 26px;
  color: #ffffff;
  text-align: center;
  margin-top: 16px;
}
.re_title {
  width: 694px;
  height: 31px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 55px auto 0;
}
.re_box {
  width: 700px;
  margin: 25px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.re_item {
  width: 218px;
  height: 266px;
  background-color: #2c2729;
  margin-top: 17px;
  border-radius: 16px;
  border: 1px solid #796553;

}
.coin_sm{
  width: 35px;
  height: 35px;
}
.re_item_top{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  margin-top: 30px;
}
.g_color{
  color: #ffffff;
}
.s_color{
  color: #999999;
}
.money{
  font-size: 36px;
  color:#fbd960 ;
  width: 100%;
  line-height: 80px;
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid #402F35;
}
.mask {
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
}

.modal_content {
    width: 500px;
    height: 300px;
    margin: 700px auto 0;
    overflow: hidden;
    z-index: 999;
    background-color: #ffffff;
    position: relative;
    border-radius: 30px;
}

.modal_title {
    text-align: center;
    font-size: 32px;
    color: #111111;
    margin-top: 10px;
}

.btns_box {
    height: 98px;
    width: 500px;
    display: flex;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #efefef;
 
}

.btn_item_cancel {
    width: 249px;
    height: 98px;
    color: #111111;
    line-height: 98px;
    text-align: center;
    font-size: 30px;
    border-right: 1px solid #efefef;
}

.btn_item_enter {
    width: 250px;
    height: 98px;
    color: #409eff;
    line-height: 98px;
    text-align: center;
    font-size: 30px;
}
.tip_content{
  text-align: center;
  margin-top: 45px;
  font-size: 26px;
  color: #666666;
}
.agreement{
  text-align: center;
  width: 100%;
  height: 80px;
  color: #ffffff;
  line-height: 80px;
  position: fixed;
  left: 0;
  bottom: 0;
}
</style>